<form [formGroup]="formGroup" *ngIf="!showFullActivateTarget">
  <div class="row">
    <div class="col-12 p-4 text-center" *ngIf="!targetSelected">
      <h3>{{ 'Choose a Target' | translate }}</h3>
      <h4>{{ 'Select the Base or Top Target Group' | translate }}</h4>
    </div>
    <div class="col-12 p-4 text-center" *ngIf="targetSelected">
      <h3>{{ 'from' | translate }} {{ currency }}{{ selectedTargetMinValue | number }}</h3>
      <h4>{{ selectedTargetText | translate }}</h4>
      <div class="mt-2 footer-copy">{{ footerCopyText | translate }}</div>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="{ 'col-6': isSingleForm, 'col-12': !isSingleForm }">
      <div class="row" *ngIf="allowTargetUpdate">
        <ng-container *ngIf="formGroup.enabled">
          <div class="col-6 border-right">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="1"
                formControlName="targetOption"
                (change)="onTargetSelectionChange(1)"
                [disabled]="!allowTargetUpdate"
              />
              <label class="form-check-label" for="baseTarget">
                {{ 'Base Target Group' | translate }}
              </label>
            </div>
            <div class="target-table">
              <div class="table-header d-flex flex-row justify-content-start align-items-center p-2">
                <div class="col-6">{{ 'Target' | translate }} ({{ currency }})</div>
                <div class="col-6">{{ 'Rebate' | translate }} ({{ currency }})</div>
              </div>
              <div class="table-body">
                <div
                  class="table-row d-flex flex-row justify-content-start align-items-center p-2"
                  *ngFor="let t of targetOptionsBase"
                >
                  <div class="col-6">{{ t.salesTarget | number }}</div>
                  <div class="col-6">{{ t.entryRebate | number }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="2"
                formControlName="targetOption"
                (change)="onTargetSelectionChange(2)"
                [disabled]="!allowTargetUpdate"
              />
              <label class="form-check-label" for="topTarget">
                {{ 'Top Target Group' | translate }}
              </label>
            </div>
            <div class="target-table">
              <div class="table-header d-flex flex-row justify-content-start align-items-center p-2">
                <div class="col-6">{{ 'Target' | translate }} ({{ currency }})</div>
                <div class="col-6">{{ 'Rebate' | translate }} ({{ currency }})</div>
              </div>
              <div class="table-body">
                <div
                  class="table-row d-flex flex-row justify-content-start align-items-center p-2"
                  *ngFor="let t of targetOptionsTop"
                >
                  <div class="col-6">{{ t.salesTarget | number }}</div>
                  <div class="col-6">{{ t.entryRebate | number }}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!formGroup.enabled">
          <p
            [innerHtml]="
              'Thank you, your {{type}} target option has been successfully updated.'
                | translate : { type: isPcsd ? 'IDG' : 'ISG' }
            "
          ></p>
        </ng-container>
      </div>

      <div class="row" *ngIf="!allowTargetUpdate">
        <div class="col-12">
          <p>{{ 'Your target has already been updated for this Quarter' | translate }}</p>
        </div>
      </div>

      <div
        class="form-group mt-3 d-flex justify-content-center"
        *ngIf="allowTargetUpdate && !showActivateButton && formGroup.enabled"
      >
        <button
          type="button"
          class="btn btn-info mt-3"
          (click)="activateTarget()"
          [disabled]="updateTargetEnabled === false"
        >
          {{ 'Update target' | translate }}
        </button>
      </div>

      <div class="row">
        <div class="col-12 border-right pt-4" *ngIf="currentDistributorName">
          <p>{{ 'Your current distributor is ' | translate }} {{ currentDistributorName }}</p>
          <p>{{ 'You can change your main Distributor from the list below:' | translate }}</p>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'col-6': isSingleForm, 'col-12': !isSingleForm }">
      <hr />

      <h5>{{ 'Select your Lenovo {{type}} distributor' | translate: {type: isPcsd ? 'IDG' : 'ISG'} }}</h5>

      <div class="form-group mt-3">
        <app-custom-select-form-control
          [dataProvider]="distributors"
          [placeholder]="'Please select your distributor' | translate"
          [labelField]="'distributorName'"
          [valueField]="'companyId'"
          formControlName="chosenDistributorId"
        ></app-custom-select-form-control>
      </div>

      <div class="form-group mt-3 d-flex justify-content-center">
        <button type="button" class="btn btn-info" (click)="updateDistributor()" *ngIf="allowDistributorUpdate">
          {{ 'Update distributor' | translate }}
        </button>
      </div>

      <div class="form-group mt-3 d-flex justify-content-center" *ngIf="showActivateButton">
        <button
          type="button"
          class="btn btn-outline-info"
          (click)="activateTarget()"
          *ngIf="!targetConfirmed"
          [disabled]="!targetSelected"
        >
          {{ 'Activate {{type}} target' | translate: {type: isPcsd ? 'IDG' : 'ISG'} }}
        </button>
        <button type="button" class="btn btn-outline-info" (click)="deactivateTarget()" *ngIf="targetConfirmed">
          {{ 'Deactivate' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
<div class="d-flex justify-content-center align-items-center py-5" *ngIf="showFullActivateTarget">
  <button type="button" class="btn btn-info" (click)="showTargetOptions()">
    {{ 'Activate {{type}} target' | translate: {type: isPcsd ? 'IDG' : 'ISG'} }}
  </button>
</div>
