import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { Reseller } from 'src/app/core/model/lenovo/reseller';

import { ProgramStatus } from '../../../core/enum/program-status.enum';
import { CustomListComponent } from '../custom-list/custom-list.component';

@UntilDestroy()
@Component({
  selector: 'app-custom-distributors-search-list',
  templateUrl: './custom-distributors-search-list.component.html',
  styleUrls: ['./custom-distributors-search-list.component.scss'],
})
export class CustomDistributorsSearchListComponent extends CustomListComponent implements OnInit {
  @Output() inviteClick = new EventEmitter<Reseller>();
  programDateFormat: string | undefined;
  public programStatusEnum: typeof ProgramStatus = ProgramStatus;

  constructor(protected override translate: TranslateService, private authStore: AuthStoreService) {
    super(translate);
  }

  invite(reseller: Reseller): void {
    this.inviteClick.emit(reseller);
  }

  ngOnInit() {
    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((userSettings) => {
      this.programDateFormat = (userSettings!.user as any).program?.dateFormat;
    });
  }
}
