<div class="h-100 d-flex justify-content-center align-items-center">
  <div class="pt-3 pt-sm-0 w-75 targets-table">
    <div class="table-header d-flex flex-row justify-content-start align-items-center p-2">
      <div class="col-1"></div>
      <div class="col-2">
        {{ 'Band' | translate }}
      </div>
      <div class="col-5">
        {{ 'Revenue' | translate }}
      </div>
      <div class="col-5">
        {{ 'Rebate' | translate }}
      </div>
    </div>
    <div class="table-body">
      <div
        class="table-row d-flex flex-row justify-content-start align-items-center p-2"
        *ngFor="let option of salesTargetOptions"
      >
        <div class="col-1">
          <fa-icon
            [icon]="['fas', 'circle-check']"
            [size]="'lg'"
            *ngIf="showCompletedCheck && rebateSummary && option.salesTarget < rebateSummary.nextTarget"
          ></fa-icon>
        </div>
        <div class="col-2">{{ option.band }}</div>
        <div class="col-5">{{ option.currency.symbol }}{{ option.salesTarget | number }}</div>
        <div class="col-5">{{ option.currency.symbol }}{{ option.entryRebate | number }}</div>
      </div>
    </div>
  </div>
</div>
