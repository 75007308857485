<footer class="footer">
  <div class="h-100 container-fluid">
    <div class="h-100 row">
      <div class="w-75 d-flex justify-content-start align-items-center">
        <div class="footer-item">
          <fa-icon class="me-1" [icon]="['fal', 'copyright']" size="xs"></fa-icon>
          <span>Lenovo {{ this.year }}</span>
        </div>
        <div class="footer-item">
          <a href="{{ termsAndConditionsUrl$ | async }}" target="_blank">{{ 'Terms & Conditions' | translate }} </a>
        </div>
        <div class="footer-item">
          <a href="{{ privacyUrl }}" target="_blank">{{ 'Privacy' | translate }} </a>
        </div>
      </div>
      <div class="w-25 lenovo-logo">
        <div class="w-100 h-100 d-flex justify-content-end align-items-center">
          <img src="https://mmicdn.s3.eu-central-1.amazonaws.com/LenovoLeapMX/WebsiteImages/Logos/lenovo+logo.png" />
        </div>
      </div>
    </div>
  </div>
</footer>
