import { Component } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionsStoreService } from 'src/app/core/store/terms-and-conditions-store.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-terms-conditions-accept-dialog',
  templateUrl: './terms-conditions-accept-dialog.component.html',
  styleUrls: ['./terms-conditions-accept-dialog.component.scss'],
})
export class TermsConditionsAcceptDialogComponent extends AbstractDialogComponent {
  tnCsChecked = false;
  public termsAndConditionsUrl$ = this.termsAndConditionsStore.termsAndConditionsUrl$;

  constructor(
    public activeModal: NgbActiveModal,
    private userStore: UserStoreService,
    private termsAndConditionsStore: TermsAndConditionsStoreService
  ) {
    super();
    this.termsAndConditionsStore.getTermsAndConditions();
  }

  onSubmit(): void {
    const acceptedTerms = this.tnCsChecked;
    if (acceptedTerms) {
      this.userStore.acceptTermsConditions();
      this.activeModal.close('Save Click');
    }
  }
}
