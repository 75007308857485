import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';

import { RebateSummary } from '../../../core/model/lenovo/rebate-summary';
import { SalesTargetOption } from '../../../core/model/lenovo/sales-target-option';
import { UserStoreService } from '../../../core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-rebate-summary',
  templateUrl: './rebate-summary.component.html',
  styleUrls: ['./rebate-summary.component.scss'],
})
export class RebateSummaryComponent implements OnInit {
  rebateSummary: RebateSummary | null;
  progressSegments: number[] = [0, 0, 0, 0];
  salesTargetOptions: SalesTargetOption[];

  currencySymbol$ = this.userStore.currency$.pipe(
    filter(Boolean),
    map((currency) => currency.symbol)
  );

  constructor(private userStore: UserStoreService, private authStore: AuthStoreService) {}

  ngOnInit() {
    this.authStore.isRegularUser$.pipe(untilDestroyed(this), filter(Boolean)).subscribe(() => {
      this.userStore.getSalesTargetOptions();
      this.userStore.getRebateSummary();
    });
    this.userStore.rebateSummary$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((rebateSummary) => {
      this.rebateSummary = rebateSummary;
      const { qtrRevLocalCurrency } = this.rebateSummary;

      this.userStore.salesTargetOptions$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((salesTargetOptions) => {
        this.salesTargetOptions = salesTargetOptions;
      });
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.salesTargetOptions.length; i++) {
        this.progressSegments[i] = qtrRevLocalCurrency >= this.salesTargetOptions[i].salesTarget ? 1 : 0;
      }
    });
  }
}
