<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'dateInvited'; let row">
    {{ row.dateInvited | date : programDateFormat ?? 'dd-MM-yyyy HH:mm' }}
  </ng-container>
  <ng-container *libGenericListCustomItemRenderer="'programStatus'; let row">
    <ng-container *ngIf="row.programStatus !== programStatusEnum.INVITE">
      {{ row.programStatus }}
    </ng-container>
    <button
      type="button"
      class="btn btn-primary"
      (click)="invite(row)"
      *ngIf="row.programStatus === programStatusEnum.INVITE"
    >
      {{ 'Invite' | translate }}
    </button>
  </ng-container>
</lib-generic-list>
